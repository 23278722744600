import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { IoTriangleSharp } from 'react-icons/io5';
import { FaSquareFull } from 'react-icons/fa';
import { BsFillCircleFill } from 'react-icons/bs';
import { FiCheck } from 'react-icons/fi';

import Header from '../../components/Header';
import Api from '../../configs/Api';
import { ReactComponent as CollectionIcon } from './images/collection-icon.svg';
import { ReactComponent as TriangleIcon } from './images/triangle-icon.svg';
import { ReactComponent as NonagonIcon } from './images/nonagon-icon.svg';
import { LIGHT_COLOR_CATEGORIES } from '../../configs/Card';
import SmallScreen from '../../components/SmallScreen';

const getCard = (card, onClick) => {
  const isTextBlack = LIGHT_COLOR_CATEGORIES.includes(card.category);

  // Source: https://codepen.io/w3collective/pen/PoZyZEg
  return (
    <div
      className={`xl:w-60 xl:h-56 w-48 h-44 cursor-pointer group`}
      style={{ perspective: '1000px' }}
      key={`card-container-${card.id}`}
      onClick={() => onClick(card)}
    >
      <div
        className={`relative w-full h-full transition-transform duration-500 ${card.isSelected ? '' : 'group-hover:rotate-y-180'}`}
        style={{transformStyle: 'preserve-3d'}}
      >
        <div
          key={`card-${card.id}`}
          className={`absolute w-full h-full ${card.isSelected ? 'brightness-75' : ''} ${isTextBlack ? 'text-black' : 'text-white'} drop-shadow-xl p-3 flex flex-col flex-wrap justify-between m-2`}
          style={{ backgroundColor: card.color, backfaceVisibility: 'hidden' }}
        >
          <div>
            <h3 className='font-semibold xl:text-2xl text-lg xl:mb-2 mb-0.5'>{card.competency}</h3>
            <h3 className='xl:text-sm text-xs'>{card.description}</h3>
          </div>

          {card.shape === 'Circle' && (<BsFillCircleFill className={`xl:w-8 xl:h-8 x-7 h-7 fill-white`} />)}
          {card.shape === 'Triangle' && (<IoTriangleSharp className={`xl:w-8 xl:h-8 x-7 h-7 fill-white`} />)}
          {card.shape === 'Square' && (<FaSquareFull className='xl:w-8 xl:h-8 x-7 h-7 fill-white' />)}
        </div>

        <div
          key={`card-back-${card.id}`}
          className={`absolute -left-4 w-full h-full bg-white text-black drop-shadow-xl p-4 flex flex-col flex-wrap justify-between m-2 rotate-y-180`}
          style={{ backfaceVisibility: 'hidden' }}
        >
          <div>
            <h3 className='font-semibold mb-2'>{card.category}</h3>
            <h3 className=''>{card.subCategory}</h3>
          </div>

          {card.shape === 'Circle' && (<BsFillCircleFill className={`xl:w-8 xl:h-8 x-7 h-7`} style={{ fill: card.color }} />)}
          {card.shape === 'Triangle' && (<IoTriangleSharp className={`xl:w-8 xl:h-8 x-7 h-7`} style={{ fill: card.color }} />)}
          {card.shape === 'Square' && (<FaSquareFull className='xl:w-8 xl:h-8 x-7 h-7' style={{ fill: card.color }} />)}
        </div>

        {card.isSelected && (
          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-4'>
            <FiCheck size={40} color={`#5d5d5d`} />
          </div>
        )}
      </div>
    </div>
  );
}

const BackpackDetailPage = () => {
  const navigate = useNavigate();
  const { id, backpackId } = useParams();

  const [collection, setCollection] = useState();
  const [collections, setCollections] = useState([]);
  const [cards, setCards] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    Api.get(`/collections`)
      .then((response) => {
        setCollections(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    Api.get(`/collections/${id}/backpacks/${backpackId}`)
      .then((response) => {
        setCollection(response.data);
        const cardData = response.data.cards.map((card) => ({ id: card._id, isSelected: false, ...card }));
        setCards(cardData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, backpackId]);

  const PreselectedPackIcon = ({ preselectedPack }) => {
    let collectionName = '';
    collections.forEach((collection) => {
      collection.backpacks.forEach((backpack) => {
        if (backpack.id === preselectedPack._id) {
          collectionName = collection.name;
        }
      });
    });

    // TODO: Add missing icons
    if (collectionName === 'Education for Sustainable Development (UNESCO cross-cutting competencies)') {
      return (<CollectionIcon fill={collection.color} className='w-16 mr-4' />);
    } else if (collectionName === 'Lifewide Experience and Lifelong Learning') {
      return (<TriangleIcon fill={collection.color} className='w-12 mr-4' />);
    } else if (collectionName === 'Skills, Competencies and Specialisms for cultural workers and students') {
      return (<NonagonIcon fill={collection.color} className='w-16 mr-4' />);
    } else if (collectionName === 'Social Action and Communities') {
      return (<></>);
    } else if (collectionName === 'Transcultural and Collaborative Practices (UNESCO Intercultural Competencies)') {
      return (<></>);
    }
    return (<></>);
  }

  const onClickCard = (card) => {
    const newCards = cards.map((c) => {
      if (c.id === card.id) {
        return { ...c, isSelected: !c.isSelected };
      }
      return c;
    });
    setCards(newCards);
  };

  const onClickConfirm = () => {
    setIsProcessing(true);
    const cardIds = cards.filter(card => card.isSelected).map(card => card.id);
    Api.post(
      'collections/backpacks/results/',
      { 'cards': cardIds, backpack: backpackId },
    )
      .then(response => {
        const { result_id } = response.data;
        setTimeout(() => {
          navigate(`/result/${result_id}`);
          setIsProcessing(false);
        }, 2000)
      })
      .catch(error => {
        console.log(error);
        setIsProcessing(false);
      });
  }

  return (
    <div>
      <Header isFixedPosition={true} />

      <SmallScreen />

      {collection && (
        <div className='hidden container lg:flex mt-28 mb-2 py-8 items-center justify-between'>
          <div className='cursor-pointer text-gray-500 flex justify-center items-center' onClick={() => navigate(-1)}>
            <FiArrowLeft size={20} className="mr-1" />
            Back
          </div>
          <div className='flex items-center'>
            <PreselectedPackIcon preselectedPack={collection} />
            <div className='text-center'>
              <h3 className='text-gray-800 text-3xl font-bold mb-2'>Refine Selection for {collection.name}</h3>
              <p className='text-gray-600'>Select up to 6 cards that best represent the capabilities and competencies relevant to you and your learning</p>
            </div>
          </div>
          <div></div>
        </div>
      )}

      {collection && ['Planning and Preparating', 'Research and Discovery', 'Writing and Doing', 'Reflect and Evaluate', 'Present and Deliver'].includes(collection.name) ? (
        <div className='container'>
          <h3 className='text-lg font-semibold text-gray-400 mb-2'>Social action EPQ (Core)</h3>
          <div className='hidden lg:grid container w-full grid grid-flow-row-dense grid-cols-5 gap-2 mb-12'>
            {cards.filter((card) => ['Engagement', 'Social practices'].includes(card.subCategory)).map((card) => getCard(card, onClickCard))}
          </div>
          <h3 className='text-lg font-semibold text-gray-400 mb-2'>{collection.name}</h3>
          <div className='hidden lg:grid container w-full grid grid-flow-row-dense grid-cols-5 gap-2 mb-20'>
            {cards.filter((card) => !['Engagement', 'Social practices'].includes(card.subCategory)).map((card) => getCard(card, onClickCard))}
          </div>
        </div>
      ) : (
        <div className='hidden lg:grid container w-full grid grid-flow-row-dense grid-cols-5 gap-2 mb-20'>
          {cards.map((card) => getCard(card, onClickCard))}
        </div>
      )}


      <div className='hidden lg:flex sticky bottom-20 flex justify-end pr-8'>
        <div className={`px-4 bg-white text-center border-2 ${cards.filter(card => card.isSelected).length !== 6 ? 'border-gray-400':'border-black'} p-2 cursor-pointer`}>
          {cards.filter(card => card.isSelected).length !== 6 ? (<h3
            className='font-bold text-2xl text-gray-400'
          >
            Select 6 cards
          </h3>) : (<h3
            className='font-bold text-2xl'
            onClick={!isProcessing ? onClickConfirm : undefined}
          >
            {isProcessing ? ('Loading...') : ('Confirm')}
          </h3>)}

        </div>
      </div>

    </div>
  );
}
export default BackpackDetailPage;
